import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider, { Settings } from "react-slick";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import { COLORS } from "../utils/consts";
import Accordion from "../components/Accordion";
import VerticalMenu from "../components/VerticalMenu";
import BottomCommonSection from "../components/BottomCommonSection";
import Arrow from "../svgComponents/Arrow";
import { getIsVertical } from "../utils/getIsVertical";

import "../styles/veselibas-centrs-liepaja.css";

const sliderSettings: Settings = {
    arrows: false,
    accessibility: false,
    dots: true,
};

export default function HealthCenterLiepaja() {
	const isVertical = getIsVertical();
	const sliderRef = React.useRef<Slider>(null);

	const handleCarouselArrowsClick = React.useCallback((isRight: boolean) => {
		if (sliderRef.current) {
			sliderRef.current[isRight ? "slickNext" : "slickPrev"]();
		}
	}, []);

	return (
		<Layout pageId={PageId.HealthCenterLiepaja}>
			<Seo
                title="Sociālās aprūpes centrs"
                description="Rūres senioru aprūpe (pansionāts) ir ērta dzīves vieta cilvēkiem, kas grib justies droši medicīnas speciālistu tuvumā, dzīvot harmoniskā un zaļā vidē un pavadīt vecumdienas pašpietiekami."
            />
			<div className="contentBlock topTextSection">
				<h1 className="topText">
                    Rūres senioru aprūpe (pansionāts) ir ērta dzīves vieta cilvēkiem, kas grib justies droši medicīnas speciālistu tuvumā, dzīvot harmoniskā un zaļā vidē un pavadīt vecumdienas pašpietiekami.
				</h1>
			</div>
            <div className="contentBlock noPadding" style={{ display: "flex" }}>
                <iframe
                    src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FRure.lv%2Fvideos%2F254396180549112%2F&show_text=false&width=560&t=0"
                    style={{ border: "none", overflow: "hidden", marginBottom: "-0.1rem", flexGrow: 1, aspectRatio: "16 / 9" }}
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    loading="lazy"
                ></iframe>
            </div>
            <div className="contentBlock noPadding">
                <table className="everydayTable">
                    <thead>
                        <tr>
                            <td colSpan={3} style={isVertical ? { fontSize: "3rem", padding: "5rem", borderBottom: `1px solid ${ COLORS.lightGreen }` } : {}}>
                                Rūre ikdienā
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p>Patstāvību<br /> veicinoša vide</p>
                                <p>Rūre nav slimnīca — tā ir veselības un aprūpes vieta, kas nodrošina ērtu dzīvi un atbalsta seniorus viņu ikdienā, aicinot pašiem iesaistīties savas veselības uzlabošanā un palīdzot būt veselīgiem un aktīviem pēc iespējas ilgāku laiku.</p>
                            </td>
                            <td>
                                <p>Nepārtraukts sociālais<br /> un medicīniskais atbalsts</p>
                                <p>Sākot ar maltītēm un beidzot ar kultūras pasākumiem — visi Rūre notikumi ir īpaši piemēroti klientu vēlmēm un veicina gan ķermeņa, gan prāta labsajūtu. Kultūras un sociālajās aktivitātēs regulāri aicinām iesaistīties arī Rūre klientu radiniekus.</p>
                            </td>
                            <td>
                                <p>Īpaši pielāgotas<br /> ikdienas aktivitātes</p>
                                <p>Rūre ir piemērota vieta gan senioriem, kuriem nepieciešama nepārtraukta medicīniska uzraudzība, gan tiem, kuri ir patstāvīgi savā ikdienā. Ērti un ātri pieejami medicīnas pakalpojumi ļauj ilgstoši uzturēt veselību un justies droši arī neparedzamās situācijās.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="contentBlock noPadding" style={{ height: isVertical ? "60rem" : "" }}>
				<StaticImage
					src="../images/veselibas-centrs-liepaja/1.png"
					quality={95}
					formats={["auto", "webp", "avif"]}
					alt="Veselības centrs Liepājā"
					placeholder="blurred"
                    style={{ height: "100%" }}
				/>
                {isVertical ? null : <div style={{ borderTop: `1px solid ${COLORS.lightGreen}`, textAlign: "center", padding: "0.8rem 0", fontStyle: "italic" }}>Rūre veselības centra pagalms Labraga ielā 11, Liepājā.</div>}
            </div>
            <div className="contentBlock priceSection">
                <div className="priceDescription">
                    <p>Saviem klientiem medicīnas pakalpojumus nodrošinām atkarībā no nepieciešamā veselības aprūpes līmeņa — no vienkāršas rehabilitācijas vai ikdienas atbalsta līdz augstas kvalitātes paliatīvai aprūpei, Rūre piedāvā visaptverošus veselības pakalpojumus dažāda vecuma pacientiem.</p>
                    <p>Ikvienam, kas kļūst par Rūre klientu 1.vizītes laikā tiek veikta multidisciplināra veselības pārbaude, iesaistot veselības, sociālās un rehabilitācijas aprūpes profesionāļus, pēc kuras tiek noteikts nepieciešamais veselības aprūpes līmenis.</p>
                </div>
                <div className="priceMenu">
                    <Accordion
                        items={[
                            {
                                Title: <h2 style={isVertical ? { fontSize: "6rem", whiteSpace: "nowrap", padding: "3rem 0 1rem" } : {}}>1. Līmenis</h2>,
                                Content: (
                                    <div className="pricesContent">
                                        <p className="pricesDescription">Klients ir pietiekami spēcīgs, lai ikdienas nodarbes veiktu pats, veselības uzraudzība un regulāras medicīniskās pārbaudes tiek veiktas pie Rūre ģimenes ārsta.</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>29<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Trīsvietīga istaba</td>
                                                </tr>
                                                <tr>
                                                    <td>39<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Divvietīga istaba </td>
                                                </tr>
                                                <tr>
                                                    <td>44<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Vienvietīga istaba </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ),
                            },
                            {
                                Title: <h2 style={isVertical ? { fontSize: "6rem", whiteSpace: "nowrap", padding: "3rem 0 1rem" } : {}}>2. Līmenis</h2>,
                                Content: (
                                    <div className="pricesContent">
                                        <p className="pricesDescription">Klientam ir nepieciešama ikdienas palīdzība ar medikamentu lietošanu, tomēr nav nepieciešama cita veida stacionārā palīdzība. Regulāras veselības pārbaudes tiek veiktas pie ģimenes ārsta Rūre veselības un aprūpes vietā.</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>35<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Trīsvietīga istaba</td>
                                                </tr>
                                                <tr>
                                                    <td>43,5<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Divvietīga istaba </td>
                                                </tr>
                                                <tr>
                                                    <td>49<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Vienvietīga istaba </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ),
                            },
                            {
                                Title: <h2 style={isVertical ? { fontSize: "6rem", whiteSpace: "nowrap", padding: "3rem 0 1rem" } : {}}>3. Līmenis</h2>,
                                Content: (
                                    <div className="pricesContent">
                                        <p className="pricesDescription">Klientam ir grūtības pārvietoties vai būt neatkarīgam, veicot ikdienas nodarbes, nepieciešamo medicīnisko un ikdienas atbalstu sniedz Rūre Liepāja komanda.</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>40<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Trīsvietīga istaba</td>
                                                </tr>
                                                <tr>
                                                    <td>55<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Divvietīga istaba </td>
                                                </tr>
                                                <tr>
                                                    <td>70<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Vienvietīga istaba </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ),
                            },
                            {
                                Title: <h2 style={isVertical ? { fontSize: "6rem", whiteSpace: "nowrap", padding: "3rem 0 1rem" } : {}}>4. Līmenis</h2>,
                                Content: (
                                    <div className="pricesContent">
                                        <p className="pricesDescription">Klients ir hroniski guļošs, tam nepieciešama nepārtraukta aprūpe un medikamentu nodrošināšana, kas tiek veikta Rūre Liepāja veselības un aprūpes vietā.</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>49<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Trīsvietīga istaba</td>
                                                </tr>
                                                <tr>
                                                    <td>64<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Divvietīga istaba </td>
                                                </tr>
                                                <tr>
                                                    <td>75<span>€</span></td>
                                                    <td>/ Dienā</td>
                                                    <td>Vienvietīga istaba </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="contentBlock noPadding" style={{ borderBottom: "none" }}>
                <div className="rureImageCarousel">
                    <Slider {...sliderSettings} className="carousel" ref={sliderRef}>
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c1.png"
                            alt="Veselības centrs Liepājā (1)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c2.png"
                            alt="Veselības centrs Liepājā (2)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c3.png"
                            alt="Veselības centrs Liepājā (3)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c4.png"
                            alt="Veselības centrs Liepājā (4)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c5.png"
                            alt="Veselības centrs Liepājā (5)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                    </Slider>
                    {
                        !isVertical && (
                            <div style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                display: "flex",
                                justifyContent: "space-between",
                                pointerEvents: "none",
                            }}>
                                <div className="carouselArrowContainer" onClick={() => handleCarouselArrowsClick(false)}>
                                    <Arrow fill={COLORS.darkGreen} style={{ transform: "rotate(90deg)", height: "2rem" }} />
                                </div>
                                <div className="carouselArrowContainer" onClick={() => handleCarouselArrowsClick(true)}>
                                    <Arrow fill={COLORS.darkGreen} style={{ transform: "rotate(-90deg)", height: "2rem" }} />
                                </div>
                            </div>
                        )
                    }
                </div>
                <div style={{ borderTop: `1px solid ${COLORS.lightGreen}`, textAlign: "center", padding: "0.8rem 0", fontStyle: "italic" }}>{isVertical ? null : "Rūre klientiem pieejamas vienvietīgas, divvietīgas un trīsvietīgas istabiņas."}</div>
            </div>
            <div className="contentBlock">
                <div className="howToBecomeClient">
                    <div className="howToBecomeClientTitle">Kā kļūt par<br /> Rūre klientu?</div>
                    <div>
                        <p>Katram Latvijas senioram, kurš saniedzis pensijas vecumu ir tiesības saņemt veselības un aprūpes pakalpojumus kādā no Latvijas sociālās aprūpes vietām.</p>
                        <p>Sadarbojoties ar Liepājas pašvaldību, arī Rūre piedāvā augstas kvalitātes sociālās aprūpes pakalpojumus gan vietējiem, gan citu reģionu senioriem.</p>
                    </div>
                </div>
            </div>
            <div className="contentBlock noPadding" style={isVertical ? { padding: "3rem" } : {}}>
                <VerticalMenu
                    items={[
                        {
                            Title: (
                                <div className="accordionBigNum">01</div>
                            ),
                            Content: (
                                <div>
                                    <p>Pretendējot uz pašvaldības apmaksātu pakalpojumu, sazinieties ar savas pašvaldības sociālo dienestu norādot, ka vēlaties kļūt par Rūre klientu. Uz pašvaldības apmaksātu vietu var pretendēt iedzīvotāji no pašvaldībām, kam ir noslēgta sadarbība ar Rūre.</p>
                                    <p>Piesakoties uz pašvaldības nodrošinātu sociālās aprūpes pakalpojumu senioriem, tas izmaksās 85% no pensijas apmēra, savukārt, starpību no kopējām pakalpojuma izmaksām segs pašvaldība. Pašvaldības nodrošināto aprūpes vietu skaits ir ierobežots.</p>
                                </div>
                            ),
                            SubTitle: <p>Pieteikšanās</p>,
                        },
                        {
                            Title: (
                                <div className="accordionBigNum">02</div>
                            ),
                            Content: (
                                <div>
                                    <p>Pēc pieteikšanās vienosimies par laiku pirmajai vizītei, kuras laikā iepazīsimies, veiksim nepieciešamās veselības pārbaudes un vienosimies par piemērotāko aprūpes līmeni un izmaksām, kā arī iepazīstināsim ar pieejamajiem papildu pakalpojumiem.</p>
                                    <p>Pēc veselības pārbaudes mēs Jūs iepazīstināsim ar Rūre Liepāja komandu, telpām un teritoriju, kā arī pastāstīsim par mūsu ikdienu, tradīcijām un Rūre aktuālajiem notikumiem.</p>
                                </div>
                            ),
                            SubTitle: <p>Pirmā vizīte</p>,
                        },
                        {
                            Title: (
                                <div className="accordionBigNum">03</div>
                            ),
                            Content: (
                                <div>
                                    <p>Uzsākot dzīvi Rūre Liepāja veselības un aprūpes vietā, gan Jums, gan Jūsu radiniekiem ik mirkli būsim līdzās, lai atbalstītu visos ikdienas notikumos.</p>
                                    <p>Katram Rūre klientam nodrošinām iespēju pašam plānot savu ikdienu, vienlaikus aicinot iesaistīties kultūras notikumos, regulāri informējot par jaunumiem un aktualitātēm Rūre Liepāja un organizējot kopīgus Rūre pasākumus.</p>
                                </div>
                            ),
                            SubTitle: <p>Pievienošanās Rūre</p>,
                        },
                    ]}
                />
            </div>
            <div className="contentBlock noPadding" style={{ height: isVertical ? "60rem" : "45rem" }}>
				<StaticImage
					src="../images/veselibas-centrs-liepaja/2.png"
					quality={95}
					formats={["auto", "webp", "avif"]}
					alt="Veselības centrs Liepājā"
					placeholder="blurred"
                    style={{ height: "100%" }}
				/>
            </div>
            <BottomCommonSection />
		</Layout>
	);
}
